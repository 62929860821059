/*
Author: Sander van Belleghem - Platform Apps
Author URI: https://www.cm.com/
Description:
Version: 0.0.4
*/

.cm-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.cm-icon-add:before {
        content: "\f067";
    }

    &.cm-icon-bar-chart:before {
        content: "\f080";
    }

    &.cm-icon-bars:before {
        content: "\f0c9";
    }

    &.cm-icon-calendar:before {
        content: "\f073";
    }

    &.cm-icon-check:before {
        content: "\f00c";
    }

    &.cm-icon-check-circle:before {
        content: "\f058";
    }

    &.cm-icon-clock:before {
        content: "\f017";
    }

    &.cm-icon-close:before {
        content: "\f057";
    }

    &.cm-icon-cog:before {
        content: "\f013";
    }

    &.cm-icon-download:before {
        content: "\f019";
    }

    &.cm-icon-ellipsis:before {
        content: "\f141";
    }

    &.cm-icon-envelope:before {
        content: "\f003";
    }

    &.cm-icon-excel:before {
        content: "\f1c3";
    }

    &.cm-icon-exchange:before {
        content: "\f0ec";
    }

    &.cm-icon-eye:before {
        content: "\f06e";
    }

    &.cm-icon-floppy:before {
        content: "\f0c7";
    }

    &.cm-icon-info-circle:before {
        content: "\f05a";
    }

    &.cm-icon-minus-square:before {
        content: "\f147";
    }

    &.cm-icon-pdf:before {
        content: "\f1c1";
    }

    &.cm-icon-pencil:before {
        content: "\f040";
    }

    &.cm-icon-plus-square:before {
        content: "\f196";
    }

    &.cm-icon-refresh:before {
        content: "\f021";
    }

    &.cm-icon-reply:before {
        content: "\f112";
    }

    &.cm-icon-search:before {
        content: "\f002";
    }

    &.cm-icon-star:before {
        content: "\f005";
    }

    &.cm-icon-star-o:before {
        content: "\f006";
    }

    &.cm-icon-trash:before {
        content: "\f1f8";
    }

    &.cm-icon-upload:before {
        content: "\f093";
    }

    &.cm-icon-user:before {
        content: "\f007";
    }

    &.cm-icon-write:before {
        content: "\f040";
    }

    &.cm-icon-warning:before {
        content: "\f071";
    }

    &.cm-icon-picture:before {
        content: "\f03e";
    }

    &.cm-icon-print:before {
        content: "\f02f";
    }

    &.cm-icon-credit-card:before {
        content: "\f09d";
    }

    &.cm-icon-sms:before {
        content: "\f075";
    }

    &.cm-icon-push:before {
        content: "\f10b";
    }

    &.cm-icon-back:before {
        content: "\f0d9";
    }

    &.cm-icon-back-o:before {
        content: "\f104";
    }

    &.cm-icon-forth:before {
        content: "\f0da";
    }

    &.cm-icon-forth-o:before {
        content: "\f105";
    }

    &.cm-icon-up:before {
        content: "\f0d8";
    }

    &.cm-icon-up-o:before {
        content: "\f106";
    }

    &.cm-icon-down:before {
        content: "\f0d7";
    }

    &.cm-icon-down-o:before {
        content: "\f107";
    }

    &.cm-icon-search:before {
        content: "\f002";
    }

    &.cm-icon-cloud-upload:before {
        content: "\f0ee";
    }

    &.cm-icon-list:before {
        content: "\f03a";
    }

    &.cm-icon-ban:before {
        content: "\f05e";
    }

    &.cm-icon-address-book-o:before {
        content: "\f2ba";
    }

    &.cm-icon-battery-three-quarters:before {
        content: "\f241";
    }
}